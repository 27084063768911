.N2Clipboard {
	margin: var(--spacing) 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	gap: var(--spacing05);
}

.N2Clipboard--dottedBorder {
	border: 2px dotted var(--palette-background-default-contrast);
	border-radius: calc(var(--spacing) / 2);
	padding: var(--spacing);
	text-align: center;
}

.N2Clipboard--simple {
	margin: 0;
}
