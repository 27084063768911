.N2Tuple {
	display: inline-flex;
	align-items: center;

	label {
		padding-right: var(--spacing);
		font-size: 85%;
		white-space: nowrap;
		opacity: .75;
		line-height: 1;
	}

	.MuiIcon-root {
		// align-self: center;
	}
}

.N2Tuple + .N2Tuple {
	margin-left: var(--spacing3);
}



.N2Tuples {
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--spacing3);
	row-gap: var(--spacing05);

	.N2Tuple + .N2Tuple {
		margin-left: 0;
	}
}

