@keyframes n2skeleton-loading {
    0% {
        opacity: 0.05;
    }
    100% {
        opacity: 0.2;
    }
}

.N2Skeleton {
    &-text-image {
        animation: n2skeleton-loading 1s linear infinite alternate;
        width: 70%;
        height: 10rem;
        background: var(--n2skeleton-color);
        border-radius: 16px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    &-text-title {
        animation: n2skeleton-loading .75s linear infinite alternate;
        width: 80%;
        height: 1.75rem;
        background: var(--n2skeleton-color);
        border-radius: 4px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &-text-line {
        animation: n2skeleton-loading 0.5s linear infinite alternate;
        width: 100%;
        height: 1rem;
        background: var(--n2skeleton-color);
        border-radius: 1px;
        margin-bottom: 0.5rem;
    }

    &-listitem {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        &-avatar {
            animation: n2skeleton-loading .75s linear infinite alternate;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background: var(--n2skeleton-color);
        }

        &-text {
            animation: n2skeleton-loading 0.5s linear infinite alternate;
            height: 1rem;
            background: var(--n2skeleton-color);
            border-radius: 2px;
        }
    }
}
