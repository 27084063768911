@import './breakpoints';


.N2I18N2s {
	valuePreview {
		margin-left: var(--spacing3);
	}
}

.N2I18N2s .languageBadge, .N2I18N2 .languageBadge {
	margin-right: var(--spacing2);
	border-radius: calc(var(--spacing) / 2);
	padding-inline: var(--spacing);
	background-color: var(--palette-background-paper);
	color: var(--palette-background-paper-contrast);
	white-space: nowrap;

	.flag {
		max-height: 12px;
		margin-right: calc(var(--spacing) / 2);
		vertical-align: middle;
		position: relative;
		top: -2px;
	}
}


.N2I18N2 {
	.nodes {
		border-spacing: calc(var(--spacing2) / 2);

		&_node {
			border-radius: 20px;
			padding: calc(var(--spacing) / 2) var(--spacing) calc(var(--spacing) / 2) var(--spacing);
			background-color: var(--palette-text-secondary);
			color: var(--palette-text-secondary-contrast);
			white-space: nowrap;
		}

		&_relationship {
			border: calc(var(--spacing) / 4) solid var(--palette-text-secondary);
			padding: calc(var(--spacing) / 4) var(--spacing) calc(var(--spacing) / 4) var(--spacing);
			white-space: nowrap;
		}
	}

	.codeEditor {
		border-radius: calc(var(--spacing) / 2);
		border: 1px solid var(--palette-grey-400);
		font-family: Consolas, monospace;
		font-size: 16;
		background: var(--palette-grey-900);
		color: var(--palette-grey-100);
		tab-size: 4;
	}

	.related {
		margin-top: var(--spacing2);
		padding: var(--spacing2);

		@include mdDown {
			padding: var(--spacing);
		}

		&_mark {
			color: var(--palette-text-secondary);
		}

		&_valuePreview {
			margin-left: var(--spacing3);
		}
	}

}
