@import './breakpoints';


.N2App {
	display: 'flex';

	main {
		flex-grow: 1;
		height: 100vh;
		// overflow: auto;
		@include lgUp {
			padding-left: 200px;
		}

		a {
			color: var(--palette-primary-main);
		}

		.mainMenuButton {
			position: absolute;
			z-index: 11;
			@include mdDown {
				top: 4px;
			}
			@include lgUp {
				display: none;
			}
		}
	}
}
