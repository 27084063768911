@import './breakpoints';


.N2View {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 56px auto;

	&__toolbar {
		background-color: var(--n2toolbar-background, var(--palette-primary-dark));
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		& > * {
			margin-right: var(--spacing);
		}
		&__left {
			flex-grow: 1;

			.MuiBox-root {
				display: flex;
				align-items: center;
			}
		}
		// &__right {
		// }
	}
	&__toolbar--ifBurger {
		padding-left: 56px;
		@include lgUp {
			display: none;
		}
	}
	&__toolbar--regular {
		@include mdDown {
			padding-left: 56px;
		}
		@include lgUp {
			padding-left: var(--spacing2);
			padding-right: var(--spacing2);
		}
	}

	&__content {
		// overflow: auto;
		padding: var(--spacing) var(--spacing) 0 var(--spacing);
		@include lgUp {
			padding: var(--spacing) var(--spacing2) var(--spacing) var(--spacing2);
		}
	}
}
