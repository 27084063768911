@import '../n2shared/breakpoints';


.TrainingPages {

    .MuiListItem-root {
        border-radius: 4px;
    }

    .MuiListItem-root.todo {
        border: 2px solid var(--palette-warning-main);
    }

}


.TrainingProgress {

    .bg {
        fill: none;
        stroke: rgba(0, 0, 0, 0.15);
    }

    .fg {
        fill: none;
        stroke: var(--palette-primary-main);
    }

    .label {
        font-family: Roboto;
        font-weight: 700;
        font-size: 22px;
        text-anchor: middle;
        fill: black;
        alignment-baseline: central;
    }

}

