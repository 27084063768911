@import '../n2shared/breakpoints';


.LoginForm {
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 3em;

	.n2installPWA {
		@include smDown {
			margin: 64px var(--spacing) 0 var(--spacing);
		}
		@include mdUp {
			margin: var(--spacing3);
		}
	}

	.mobileSafari {
		margin-top: 64px;

		img {
			margin-inline: 2px;
			height: 26px;
			vertical-align: middle;
			background-color: white;
			padding: 3px;
			border-radius: 2px;
		}
	}

	.MuiPaper-root {
		padding: var(--spacing2);
		text-align: center;
	}

	.logo {
		max-width: 400px;
		max-height: 30vmin;
		margin: 32px 0 32px 0;
	}

	.Signup {
		margin-bottom: var(--spacing3);
		display: grid;
		gap: var(--spacing2);
		align-items: center;
		grid-template-columns: repeat(3, 1fr);

		@include xs {
			grid-template-columns: 1fr;
			grid-template-areas:
				"firstname"
				"lastname"
				"email"
				"mobile"
				"street"
				"zip"
				"city"
				"country"
				"username"
				"password"
				"password2"
				"screenname"
				"screennameinfo"
				"age"
				"tac"
				"submit"
				"login";
		}
		@include smUp {
			grid-template-areas:
				"firstname lastname ."
				"email mobile ."
				"street street ."
				"zip city country"
				"username password password2"
				"screenname screennameinfo screennameinfo"
				"age age age"
				"tac tac tac"
				". submit ."
				". login .";
		}

		&_firstname { grid-area: firstname };
		&_lastname { grid-area: lastname };
		&_email { grid-area: email };
		&_mobile { grid-area: mobile };
		&_street { grid-area: street };
		&_zip { grid-area: zip };
		&_city { grid-area: city };
		&_country { grid-area: country };
		&_username { grid-area: username };
		&_password { grid-area: password };
		&_password2 { grid-area: password2 };
		&_screenname { grid-area: screenname };
		&_screennameinfo { grid-area: screennameinfo; justify-self: start };
		&_age { grid-area: age };
		&_tac { grid-area: tac };
		&_submit { grid-area: submit };
		&_login { grid-area: login };
	}

}
