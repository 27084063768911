@import './n2shared/breakpoints';


:root {
	--n2toolbar-background: #D9C24A;
	--n2skeleton-color: hsl(200deg, 10%, 50%);
	--telegram-color: #25a4e2;
}


body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

a {
	color: var(--palette-primary-main);
	text-decoration: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
	border: none;
	border-top: 2px dashed gray;
}

@supports (backdrop-filter: blur()) {
	.MuiBackdrop-root {
		backdrop-filter: blur(1.5px);
	}
}

::-webkit-scrollbar {
	width: 15px;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	border: none;
	background-color: var(--palette-primary-main);
}
::-webkit-scrollbar-thumb:hover {
	background-color: var(--palette-primary-light);
}
::-webkit-scrollbar-corner {
	background-color: transparent;
}

.MuiDrawer-paper::-webkit-scrollbar {
	width: 8px;
}
.MuiDrawer-paper::-webkit-scrollbar-thumb {
	border-radius: 2px;
	border: none;
	background-color: var(--palette-primary-dark);
}
.MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
	background-color: var(--palette-primary-main);
}
.MuiDrawer-paper::-webkit-scrollbar-corner {
	background-color: transparent;
}


input[type="date"]::-webkit-calendar-picker-indicator {
	cursor: pointer;
	filter: invert(1);
	opacity: .5;
}


.MuiListItem-root.Mui-selected {
	border-left: 4px solid var(--palette-primary-main);

	.MuiListItemIcon-root {
		color: var(--palette-primary-main);
	}
	.MuiListItemText-root {
		color: var(--palette-primary-main);
		font-weight: 700;
	}
}

.MuiBottomNavigation-root {
	position: absolute;
	bottom: 0;
	z-index: 10;
	width: 100vw;
}

.react-json-view {
	/* background-color: transparent !important; */
	line-height: 1em;
}


.n2pointer {
	cursor: pointer;
}

.n2row {
	display: flex;
	flex-direction: row;
	gap: var(--spacing);
	align-items: center;
}

.n2grow {
	flex-grow: 1;
}

.n2right {
	text-align: right;
}

.n2middle {
	vertical-align: middle;
}

.n2centercenter {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.n2impersonating {
	color: orange;
}


.n2success {
	color: var(--palette-success-main);
}
.n2info {
	color: var(--palette-info-main);
}
.n2warning {
	color: var(--palette-warning-main)
}
.n2error {
	color: var(--palette-error-main)
}


.n2highlight {
	position: absolute;
	z-index: 1000;
	opacity: 0.75;
	background-image: radial-gradient(circle, transparent 0%, var(--palette-primary-main) 25%, transparent 50%);
	background-size: 100% 100%;
	background-position: center center;
	transition: opacity 1.5s ease-in, background-size 1.5s ease-out;
}
.n2highlight.fading {
	opacity: 0;
	background-size: 300% 300%;
}

.n2mark_created {
	background-color: hsla(120, 100%, 50%, 0.2);
}
.n2mark_updated {
	background-color: hsla(39, 100%, 50%, 0.2);
}

.n2topAlert {
	margin-block: var(--spacing);
}

.n2spinner {
	animation: 1.5s linear infinite n2spinner;
}
@keyframes n2spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


.n2menuicon {
	margin-right: 0.5rem;
}

.N2NewAppVersion {
	position: fixed;
	left: var(--spacing);
	bottom: var(--spacing3);
	z-index: 2000;
}

img.n2flag {
	height: 16px;
	margin: 2px 8px;
	vertical-align: middle;
}

.n2indented {
	margin-left: var(--spacing2);
}

.n2chip {
	background: var(--palette-text-secondary);
	color: var(--palette-text-secondary-contrast);
	font-size: .75rem;
	padding: 1px 6px;
	white-space: nowrap;
	border-radius: 1em;
}
