@mixin xs {
	@media screen and (max-width:599px) {
		@content;
	}
}

@mixin sm {
	@media screen and (min-width:600px) and (max-width:959px) {
		@content;
	}
}
@mixin smDown {
	@media screen and (max-width:959px) {
		@content;
	}
}
@mixin smUp {
	@media screen and (min-width:600px) {
		@content;
	}
}

@mixin md {
	@media screen and (min-width:960px) and (max-width:1279px) {
		@content;
	}
}
@mixin mdDown {
	@media screen and (max-width:1279px) {
		@content;
	}
}
@mixin mdUp {
	@media screen and (min-width:960px) {
		@content;
	}
}

@mixin lg {
	@media screen and (min-width:1280px) and (max-width:1919px) {
		@content;
	}
}
@mixin lgDown {
	@media screen and (max-width:1919px) {
		@content;
	}
}@mixin lgUp {
	@media screen and (min-width:1280px) {
		@content;
	}
}

@mixin xl {
	@media screen and (min-width:1920px) {
		@content;
	}
}