.TeamGraph {
	height: 100%;

	#graphBox {
		width: 100%;
		height: 100%;
	}

	#inspectorBox {
		position: absolute;
		right: var(--spacing);
	}

	svg {
		.links {
			stroke: #777;
			stroke-opacity: .5;
		}

		.nodes {
			stroke: white;
			stroke-width: 2;

			circle {
				cursor: pointer;
			}
			circle.me {
				stroke: firebrick;
				stroke-width: 3;
			}
			circle.customer {
				stroke: black;
			}

			text {
				text-anchor: middle;
				alignment-baseline: middle;
				stroke-width: 0;
				fill: black;
				cursor: pointer;
			}
		}
	}

}
