@import '../n2shared/breakpoints';


.Team.N2View__content {
    padding: 0;
}


.Team {
    .MuiBottomNavigation-root {
        position: initial;
        bottom: initial;
        z-index: initial;
    }

    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-areas:
        "topTabs"
        "content"
        "bottomNav";

    .topTabs {
        grid-area: topTabs;
		display: grid;
		justify-items: center;

        @include xs {
            display: none;
        }
	}

    .inspectorBox {
        display: grid;
        justify-content: end;
    }
    .inspectorBox.tab0 {
		padding: var(--spacing);

        @include lgUp {
            position: absolute;
            right: var(--spacing);
        }
	}
    .inspectorBox.tab1 {
		position: absolute;
		right: var(--spacing);
	}

    .content {
        grid-area: content;
        overflow: auto;
    }

    .bottomNav {
        grid-area: bottomNav;

        @include smUp {
            display: none;
        }
    }

    .Tree {
        padding-inline: var(--spacing);

        .uplinenode {
            margin-block: var(--spacing05);
        }

        .sublevel {
            margin-left: var(--spacing);
            border-left: 2px solid var(--palette-divider);
            padding-left: var(--spacing3);
        }

        .subnode {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            gap: var(--spacing);
            cursor: pointer;
            margin-block: var(--spacing05);
        }

        .firstlineCount {
            margin-left: var(--spacing);
            display: inline-flex;
            background-color: var(--palette-primary-light);
            color: var(--palette-primary-contrastText);
            padding-right: 4px;
            border-radius: 4px;
        }

        .N2Tuple {
            border-radius: 4px;
            background-color: var(--palette-background-paper);
            color: var(--palette-background-paper-contrast);
            padding: 1px 4px 1px 4px;
        }

        .N2Skeleton-listitem {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }

    .Inspector {
        margin-block: var(--spacing);
        width: 300px;
        border: 2px solid #555;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, .85);
        padding: var(--spacing);

        header {
            margin-block: var(--spacing);
            display: flex;
            align-items: center;
            gap: var(--spacing);

            .n2flag {
                margin: 0;
            }
        }

        .address {
            margin-top: var(--spacing05);
            line-height: 1.03;
        }

        .contact {
            margin-top: var(--spacing05);
            line-height: 1.1;
        }

        .realtimeYield {
            margin-top: var(--spacing);
        }
    }
}


.NetworkId {
    background: var(--palette-background-paper);
    color: var(--palette-background-paper-contrast);
    font-size: .8rem;
    border: 1px solid black;
    border-radius: 2px;
    padding-inline: 1px 3px;
    white-space: nowrap;
}


.UserPhoto {
    border-radius: 4px;
    vertical-align: middle;
}


.UserInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: var(--spacing);

    .photo {
        line-height: initial;
        margin: 0;
        padding: 0;
    }

    .label {
        white-space: nowrap;
    }
}
.UserInfo.disabled {
    opacity: .5;
}


.YieldInfo {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing);
    font-size: .8rem;
}


.CustomerInfo {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing);
}


.Rank {
    display: flex;
    align-items: center;
    gap: var(--spacing05);

    svg {
        height: 20px;
    }

    .label {
        font-size: .75rem;
    }
}
