@import '../n2shared/breakpoints';

$drawerWidth: 200px;


.MuiDrawer-root {
	width: $drawerWidth;

	.MuiPaper-root {
		width: $drawerWidth;
		background-color: var(--palette-background-paper);
	}

	.logo {
		margin: var(--spacing3) auto;
		max-width: 90%;
		pointer-events: none;
	}

	.switchUser {
		color: var(--palette-warning-main);
		.MuiListItemIcon-root {
			color: var(--palette-warning-main);
		}
	}
}


.AppNav {
	@include lgUp {
		width: $drawerWidth;
		flex-shrink: 0;
	}
}
