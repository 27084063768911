@import './breakpoints';


.N2CMS {

	.category {
		margin-left: var(--spacing);
		border-radius: 2px;
		background-color: var(--palette-text-secondary);
		color: var(--palette-text-secondary-contrast);
		padding-inline: var(--spacing05);
		font-size: .8rem;
	}

	.tag {
		margin-left: var(--spacing);
		border-radius: 2px;
		background-color: var(--palette-text-secondary);
		color: var(--palette-text-secondary-contrast);
		padding-inline: var(--spacing05);
		font-size: .7rem;
	}

	.attachment {
		font-size: 1em;
		margin-right: var(--spacing05);
	}

	.grid {
		display: grid;
		gap: var(--spacing2);

		@include xs {
			grid-template-areas:
				"category"
				"published"
				"name"
				"summary"
				"tags"
				"text"
				"image"
				"files";
		}
		@include sm {
			grid-template-areas:
				"category published"
				"name name"
				"summary summary"
				"tags tags"
				"text text"
				"image image"
				"files files";
		}
		@include mdUp {
			grid-template-areas:
				"category published image"
				"name name image"
				"summary summary image"
				"tags tags tags"
				"text text text"
				"files files files";
		}

		&_category { grid-area: category; }
		&_published { grid-area: published; }
		&_image { grid-area: image; }
		&_name { grid-area: name; }
		&_summary { grid-area: summary; }
		&_tags {
			grid-area: tags;
			.MuiChip-root {
				margin: var(--spacing05)
			}
		}
		&_text { grid-area: text; }
		&_files { grid-area: files; }
	}


	.N2CMSPage {

		header {
			.featuredImage {
				min-height: 200px;
				max-height: 30vh;
				transition: max-height .5s;
				transition-delay: .5s;
				&:hover {
					max-height: 60vh;
				}

				img {
					object-fit: cover;
					width: 100%;
					min-height: 200px;
					max-height: 30vh;
					transition: max-height .5s;
					transition-delay: .5s;
					&:hover {
						max-height: 60vh;
					}
				}
			}

			.name {
				position: relative;
				background-color: rgba(0, 0, 0, 0.70);
				padding: var(--spacing) var(--spacing2) var(--spacing) var(--spacing2);
				transform: translateY(-130%);
				display: grid;
				align-items: center;

				h1 {
					color: white;
					margin: 0;
					padding-bottom: 0;
					line-height: 1;
					font-size: clamp(28px, 4vmin, 48px);
				}
			}
		}

		h1 {
			padding-bottom: 48px;
		}

		.text {
			margin-top: -48px;
			font-size: var(--typography-body1-fontSize);

			img {
				height: auto !important;
				max-width: 100% !important;
			}

			video {
				height: auto !important;
				max-width: 100% !important;
			}

			audio {
				display: block;
				margin: var(--spacing) auto;
			}
		}
	}

}
